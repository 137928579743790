//
// Aside
//

.aside {
	width: get($aside-config, base, width);

	// Aside Menu
	.aside-menu {
		@include perfect-scrollbar-ver-offset(4px);
		.menu-nav {
			.menu-item {
				.menu-link {
					.menu-text {
						color: #383839;
					}
				}
			}
			.menu-item.menu-item-active {
				.menu-link {
					background: #adaeaa;
				}
			}
			.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading, .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link {
					background-color: red !important;
			}
		}
	}
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	.aside {
		// Fixed Aside Mode
		.aside-fixed & {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: get($aside-config, base, zindex);
		}

		// Static Aside Mode
		.aside-static & {
			position: relative;
			z-index: 1;
		}

		// Fixed Header & Static Aside Modes
		.header-fixed.aside-static & {
			padding-top: get($header-config, desktop, default, height);
		}

		// Minimized Aside Mode
		.aside-minimize:not(.aside-minimize-hover) & {
			width: get($aside-config, base, minimized-width);
		}

		// Scrollbar Width
		@include perfect-scrollbar-ver-size(get($aside-config, base, scrollbar-width));
	}

	// Fixed Aside & Minimize Aside Hover Modes
	.aside-fixed.aside-minimize.aside-minimize-hover {
		.aside {
			width: get($aside-config, base, width);
		}

		.wrapper {
			padding-left: get($aside-config, base, minimized-width);
		}
	}

	// Fixed Aside, Fixed Header & Minimize Aside Hover Modes
	.aside-fixed.header-fixed.aside-minimize.aside-minimize-hover {
		.header {
			left: get($aside-config, base, minimized-width);
		}
	}
}

// Build Aside Menu
@include menu-ver-build-layout($aside-menu-config);

// Aside Offcanvas Panel For Mobile Mode
@include offcanvas-build(aside, tablet-and-mobile, get($aside-config, offcanvas-mobile));
